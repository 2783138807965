<template>
  <v-simple-table class="b-table-report">
    <template v-slot:default>
      <thead>
        <tr>
          <th v-for="(header, h) in headers" :key="h">{{ header.text }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, i) in items">
          <tr :key="i" :class="item.children && 'no-border-bottom'">
            <td>{{ item.name }}</td>
            <td :class="item.kinship === 'Titular' && 'primary--text'">
              {{ item.kinship }}
            </td>
            <td>
              <span class="primary--text">
                {{ formatDate(item.startDate) }}
              </span>
              <span v-if="item.startDate && item.conclusionDate"> até </span>
              <span class="primary--text">
                {{ formatDate(item.conclusionDate) }}
              </span>
            </td>
            <td class="text-right">{{ formatMoney(item.value) }}</td>
          </tr>
          <template v-if="item.children">
            <tr :key="`${i}-children`" class="b-table-report__child">
              <td class="b-table-report__child--parent">
                <span></span>{{ item.children.name }}
              </td>
              <td></td>
              <td>
                <span class="primary--text">
                  {{ formatDate(item.children.startDate) }}
                </span>
                <span
                  v-if="item.children.startDate && item.children.conclusionDate"
                >
                  até
                </span>
                <span class="primary--text">
                  {{ formatDate(item.children.conclusionDate) }}
                </span>
              </td>
              <td class="text-right">
                {{ formatMoney(item.children.value) }}
              </td>
            </tr>
          </template>
        </template>
        <tr v-if="total" class="b-table-report__count">
          <td :colspan="headers.length" class="text-right">
            <strong>Total </strong>
            <strong class="success--text">{{ formatMoney(total) }}</strong>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { formatMoney, formatDate } from '@/helpers/formatting';

export default {
  props: {
    items: {
      type: Array,
      require: true
    },
    total: {
      type: Number,
      require: true
    }
  },

  data: () => ({
    headers: [
      { text: 'Identificação' },
      { text: 'Parentesco' },
      { text: 'Data' },
      { text: 'Valor do plano unificado' }
    ]
  }),

  methods: {
    formatMoney,
    formatDate
  }
};
</script>
